/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      ishidden: 0,
      name_package : "",
      storage : 1024,
      max_upload : "unlimited",
      max_workgroup : 3,
      max_participants : 3,
      price_month : 10,
      price_year : 10,
      sizingon : "MB",
      sizingMax : "unlimited",
    }
  },
  computed: {
    loading(){
        return this.$store.getters['packages/loading'];
    },
    ismaxDisabled(){
        return this.sizingMax == "unlimited"
    }
  },
  created(){
  },
  mounted(){
  },
  methods : {
    changeSizing(value){
        this.sizingon = value
    },
    changeSizingMax(value){
        this.sizingMax = value
    },
    calculateStorageToBytes(value,converter){
        switch (converter) {
            case "MB":
                return value * Math.pow(1024, 2);
            case "GB":
                return value * Math.pow(1024, 3);
            case "TB":
                return value * Math.pow(1024, 4);
            case "PB":
                return value * Math.pow(1024, 5);
            default:
                return value
        }
    },
    save(){
        if(this.name_package != ""){
            this.$store.dispatch('packages/create',{
                ishidden : this.ishidden,
                name_package : this.name_package,
                storage : this.calculateStorageToBytes(this.storage,this.sizingon),
                max_upload : this.sizingMax == "unlimited" ? this.sizingMax : this.calculateStorageToBytes(this.max_upload,this.sizingMax),
                max_workgroup : this.max_workgroup,
                max_participants : this.max_participants,
                price_month : this.price_month,
                price_year : this.price_year
            })
            .then((oke)=>{
                this.$toast.open({
                    message: 'success',
                    type: 'success',
                });
                this.$router.push({ name: "packages" });
            })
            .catch((notoke)=>{
                this.$toast.open({
                    message: notoke,
                    type: 'error',
                });
            })
        }else{
            this.$toast.open({
                message: 'name_package not valid',
                type: 'error',
            });
        }
    }
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Packages </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
        <div class="row justify-content-md-center" >
            <div class="col-md-6">
                <b-overlay :show="loading" rounded="sm">
                    <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                        <div class="card-body">
                            <div class="form-group">
                                <label for="" class="control-label">Packages Name : </label>
                                <input type="text" class="form-control" placeholder="firstname" v-model="name_package">
                            </div>
                            <div class="mb-2">
                                <label for="" class="control-label">Storage : </label>
                                <b-input-group>
                                    <b-form-input type="number" v-model="storage"></b-form-input>
                                    <template #append>
                                    <b-dropdown :text="sizingon" variant="primary">
                                        <b-dropdown-item-button @click="changeSizing('MB')">MB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizing('GB')">GB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizing('TB')">TB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizing('PB')">PB</b-dropdown-item-button>
                                    </b-dropdown>
                                    </template>
                                </b-input-group>
                            </div>
                            <div class="mb-2">
                                <label for="" class="control-label">Max Upload : </label>
                                <b-input-group>
                                    <b-form-input type="number" v-model="max_upload" :disabled="ismaxDisabled"></b-form-input>
                                    <template #append>
                                    <b-dropdown :text="sizingMax" variant="primary">
                                        <b-dropdown-item-button @click="changeSizingMax('unlimited')">unlimited</b-dropdown-item-button>
                                        <b-dropdown-item-button @click="changeSizingMax('MB')">MB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizingMax('GB')">GB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizingMax('TB')">TB</b-dropdown-item-button>
                                        <b-dropdown-item-button  @click="changeSizingMax('PB')">PB</b-dropdown-item-button>
                                    </b-dropdown>
                                    </template>
                                </b-input-group>
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Max Workgroup : </label>
                                <input type="number" class="form-control" placeholder="max workgroup" v-model="max_workgroup">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Max Participants : </label>
                                <input type="number" class="form-control" placeholder="max participants" v-model="max_participants">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Price Month : </label>
                                <input type="number" class="form-control" placeholder="Price Month" v-model="price_month">
                            </div>
                            <div class="form-group">
                                <label for="" class="control-label">Price Year : </label>
                                <input type="number" class="form-control" placeholder="Price Year" v-model="price_year">
                            </div>
                            <b-form-checkbox
                            id="hidden"
                            v-model="ishidden"
                            name="hidden"
                            :value="1"
                            :unchecked-value="0"
                            >
                            is hidden package
                            </b-form-checkbox>
                            <div class="form-group mt-3">
                                <router-link tag="a" to="/packages" class=" mr-2 btn btn-danger btn-sm" > <i class="bx bx-left-arrow-alt"></i> Back</router-link>
                                <button class="btn btn-info btn-sm mx-2" @click="save()"> <i class="bx bx-package"></i> Create</button>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
